#excel-modal {
  position: fixed;
  width: 75%;
  max-height: 90%;
  border: 1px rgba(7,84,140,0.9) solid;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 20px;
  padding: 15px;
  font-weight: bold;
  background: white;
  transform: translate(-50%, -50%);
  z-index: 3;
  overflow: scroll;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  font-size: 10px;
}

.excel-header {
	display:inline-block;
	padding-left: 15px;
}