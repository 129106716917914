#loading {
  position: fixed;
  top: calc(50% - 60px);
  left: 50%;
  transform: translate(-50%, calc(-50% - 60px));
  width: 100%;
}

input.required {
  background-color: rgb(255, 50, 50) !important;
}

#logo {
  content: url("nevia-logo.png");
  width: 75%;
}