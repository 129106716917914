.page-selection {
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 20px;
}

.link.selected {
  font-weight: bold;
  cursor: default;
}

.link {
  margin-right: 15px;
  font-size: 26px;
  cursor: pointer;
}

.dots {
  margin-right: 10px;
  font-size: 26px;
}

.specific {
    background-color: yellow !important;
}

.none-text {
  text-align: center;
}

.list-table-header {
  cursor: pointer;
}

.datafield-list-part {
  margin-bottom: 15px;
}

#count-select-label {
  text-align: center;
}

#count-select {
  width: 75px;
}

.center-button {
  margin: auto;
}

.page-navigation-arrow {
  font-size: 26px;
  margin-right: 15px;
}
