.modal {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  pointer-events: all;
}

.confirm-area {
  position: fixed;
  display: table;
  border-radius: 2%;
  border: 1px rgba(7,84,140,0.9) solid;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 20px;
  padding: 15px;
  font-weight: bold;
  background: white;
  transform: translate(-50%, -50%);
  z-index: 10;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.confirm-area.hidden {
  display: none;
}
