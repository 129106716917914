.modal-message {
  position: fixed;
  z-index: 15;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.001);
  pointer-events: all;
}

.message-area {
  position: fixed;
  display: table;
  border-radius: 3%;
  width: 75%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: white;
  padding: 5px;
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  z-index: 10;
  background-color: green;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.message-area button {
  background-color: #005490;
  width: 45px;
  height: 45px;
  float: right;
  padding: 0px;
  font-size: 26px;
}

.message-area.Error {
  background-color: red !important;
}

.message-area.Warning {
  background-color: yellow !important;
  color: black !important;
}
