#truck-find-select-container {
  position: relative;
}

#truck-find-select {
  position: absolute;
  top: 38px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid rgba(7, 84, 140, 0.9);
}

.truck-find-select-item {
  background: white;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
  padding-left: 5px;
  border: 1px solid rgba(7, 84, 140, 0.4);
}

.truck-find-select-item:hover {
  background: rgb(182, 222, 253);
}
