.data-field-top {
  border-radius: 2%;
  width: 100%;
  text-align: center;
  line-height: 40px;
  font-size: 3vh;
  font-weight: bold;
  color: white;
  padding: 2px;
  background: rgba(7,84,140, 0.8); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(7,84,140, 0.7), rgba(7,84,140, 0.8)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(7,84,140, 0.7), rgba(7,84,140, 0.8)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(7,84,140, 0.7), rgba(7,84,140, 0.8)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(7,84,140, 0.7), rgba(7,84,140, 0.8)); /* Standard syntax */
  cursor: pointer;
}

.data-field-top.yellow {
  background: rgba(255,255,0, 0.8); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(255,255,0, 0.7), rgba(255,255,0, 0.8)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(255,255,0, 0.7), rgba(255,255,0, 0.8)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(255,255,0, 0.7), rgba(255,255,0, 0.8)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(255,255,0, 0.7), rgba(255,255,0, 0.8)); /* Standard syntax */
}

.data-field-top:hover {
  background: rgba(7,84,140,0.9); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(7,84,140, 0.8), rgba(7,84,140, 0.9)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(7,84,140, 0.8), rgba(7,84,140, 0.9)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(7,84,140, 0.8), rgba(7,84,140, 0.9)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(7,84,140, 0.8), rgba(7,84,140, 0.9)); /* Standard syntax */
}

.data-field-top.yellow:hover {
  background: rgba(255,255,0, 0.9); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(255,255,0, 0.8), rgba(255,255,0, 0.9)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(255,255,0, 0.8), rgba(255,255,0, 0.9)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(255,255,0, 0.8), rgba(255,255,0, 0.9)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(255,255,0, 0.8), rgba(255,255,0, 0.9)); /* Standard syntax */
}

.data-field-top.selected {
  background: rgba(7,84,140, 1); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(7,84,140, 0.9), rgba(7,84,140, 1)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(7,84,140, 0.9), rgba(7,84,140, 1)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(7,84,140, 0.9), rgba(7,84,140, 1)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(7,84,140, 0.9), rgba(7,84,140, 1)); /* Standard syntax */
}

.data-field-top.selected.yellow {
  background: rgba(255,255,0, 1); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, rgba(255,255,0, 0.9), rgba(255,255,0, 1)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, rgba(255,255,0, 0.9), rgba(255,255,0, 1)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, rgba(255,255,0, 0.9), rgba(255,255,0, 1)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, rgba(255,255,0, 0.9), rgba(255,255,0, 1)); /* Standard syntax */
}

.data-field-bottom {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  line-height: 15px;
  font-size: 15px;
  border: 1px rgba(7,84,140,0.9) solid;
}

.data-field-bottom span {
  padding-right: 10px;
}
