#top-contract-and-site-select {
  position: fixed;
  top: 0;
  left: 56px;
  z-index : 3;
}

#top-contract-and-site-select label {
  display: none;
}

#top-contract-and-site-select .selector {
  display: inline-block;
  width: calc(50vw - 68px);
  margin-right: 2px;
}

#top-contract-and-site-select select {
  color: white;
  font-weight: bold;
  padding: 0;
  letter-spacing: 0;
  height: 56px;
  font-size: 14px;
  text-align: center;
  text-align-last: center;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

#top-contract-and-site-select select:focus {
  color: rgb(162, 199, 65);
  border-color: rgb(162, 199, 65);
}

#top-contract-and-site-select select option {
  color: black;
}

#top-contract-and-site-select .loader {
  display: none;
}

@media (min-width: 1280px) {
  #top-contract-and-site-select {
    left: 298px;
  }
  #top-contract-and-site-select .selector {
    width: calc(50vw - 298px);
  }
}
