.construction-site-select {
    border: 1px solid black;
    width: 285px !important;
    height: 52px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(7,84,140,0.8);
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}

.construction-site-select:hover {
    background: rgba(7,84,140,1);
}

.construction-site-select.selected {
    background: rgba(7,84,140,1);
    cursor: auto;
}

#construction-site-container {
    text-align: center;
}

.construction-site-select-container {
    display: inline-block;
    padding-right: 2px;
    padding-left: 2px;
}