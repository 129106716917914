.notice-area {
  position: fixed;
  display: table;
  border-radius: 4%;
  max-width: 50vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  pointer-events: none;
  z-index: 15;
  background-color: green;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.notice-area.hidden {
  opacity: 0;
}

#Error {
  background-color: red !important;
}

#Warning {
  background-color: yellow !important;
  color: black !important;
}
