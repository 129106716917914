#menu {
  z-index: 4;
  float: left;
  width: 250px;
  position: fixed;
  bottom: 0;
  top: 0;
  overflow: hidden;
}

#menu.visible > #menu-close {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,.5);
}

#menu.hidden {
  left: -250px;
  transition: 0.4s;
}

#menu.visible {
  left: 0px;
  transition: 0.4s;
}

#menu-content {
  overflow-y: auto;
  background-color: #E5EDF3;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 250px;
}

#menu-content > label {
  cursor: pointer;
}

#menu-content > ul {
  margin: 0;
  padding: 0;
}

#menu-content > ul > li {
  cursor: pointer;
  list-style: none;
  height: 56px;
  border-bottom: 1px solid rgba(7,84,140,0.2);
  margin: 0;
}

#menu-content > ul > li:hover {
  background-color: rgba(7,84,140,0.3);
}

#menu-content > ul > li > a, #menu-content > ul > li > span {
  display: block;
  color: black;
  text-decoration: none;
  text-align: left;
  font-size: 18px;
  line-height: 56px;
  padding-left: 20px;
}

#menu-content > ul > li.selected {
  background-color: rgba(7,84,140,0.3);
  pointer-events: none;
}

#menu-content > ul > li.selected > a {
  color: white;
  font-weight: bold;
}

#menu-top {
  padding-left: 18px;
  background-color: rgb(7,84,140);
  height: 56px;
  width: 100%;
  font-weight: bold;
  font-size: 17px;
  margin: 0;
  color: white;
}

#header {
  position:fixed;
  top:0px;
  width: 100%;
  height: 56px;
  background-color: rgb(7,84,140) !important;
  z-index: 2;
}
#header button {
  float: left !important;
  margin: 4px !important;
  padding: 0 !important;
  border: 0 !important;
  width: 48px !important;
  height: 48px !important;
  background: none !important;
  line-height: 56px !important;
  transition: 0.4s !important;
}

#header button > i {
  font-size: 20px;
  color: white;
  transition: 0.4s;
  background: none !important;
}

#header > span {
  float: left;
  padding: 4px;
  border: 0;
  height: 48px;
  background: none;
  vertical-align: middle;
  font-size: 30px;
}

#header button:focus {
  outline: none !important; 
}

#header button::-moz-focus-inner {
  border: 0 !important;
}

@media (min-width: 1280px) {
  #menu-close {
    background: none !important;
  }

  .menu-hidden > #header button {
    margin-left: 250px;
  }
}